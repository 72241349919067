import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../config/firebase-config"
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import Header from '../components/header';
import '../styles/success.css'
import { api } from '../config/config';

export default function AchPaymentMethodSuccess() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const [customer,setCustomer] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const navigate = useNavigate();



  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    console.log("hello");
    savePaymentMethodToUser(window.location.href.split("=")[1]);
  }, [user, loading]);


  const savePaymentMethodToUser = async (session_id) =>{
    console.log("hello");
    const data = {
      session_id: session_id
    }

    const response = await fetch(api + '/retrieve-session-id', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    console.log(response)

    if(response.ok){
      const res = await response.json();
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const docz = await getDocs(q);
      const data = docz.docs[0].data();
      const userDocRef = doc(db,'users',docz.docs[0].id);
      await updateDoc(userDocRef, {
        ach_payment:res.setup_intent.payment_method,
      })
    }
  }


  return (
    <div className="success-container">
      <Header />
      <div className="success-content">
        <h1>Your payment method has been saved!</h1>
        <a href="/dashboard">Navigate back to dashboard</a>
      </div>
     </div>
  )
}
