import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import { useUserData } from "../components/hooks/userData";
import '../styles/home.css';

function Home() {
  const { betaTest, authCheck } = useUserData();

  return (
    <div className="home-container">
      <Header />
      <div className="home-content-container">
        <h1>Elevate your night out with fast, transparent payments.</h1>
        <div className="home-content-links">
          {!authCheck && <Link to="/login">Login</Link>}
          {authCheck && betaTest && <Link to="/password">Dashboard</Link>}
        </div>
      </div>
    </div>
  );
}

export default Home;