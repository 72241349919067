import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getFirestore, query, getDocs, where, collection, doc, updateDoc } from "firebase/firestore";
import { auth } from "../../config/firebase-config";
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';
import { api } from '../../config/config';


export const useUserData = () => {
  const [user, loading, error] = useAuthState(auth);
  const [customer, setCustomer] = useState(null);
  const [attending, setAttending] = useState([]);
  const [customerEvents, setCustomerEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ach, setACH] = useState("");
  const [wallet, setWallet] = useState("");
  const [brand, setBrand] = useState("");
  const [last4, setLast4] = useState("");
  const [authProvider, setAuthProvider] = useState("");
  const [betaTest, setBetaTest] = useState(false);
  const [identity, setIdentity] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([])
  const [uid, setUid] = useState("");
  const [phone, setPhone] = useState("");
  const [authCheck, setAuthCheck] = useState(false);
  const [transactions,setTransactions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const db = getFirestore();

  const fetchUserData = async () => {
    try {
      const querySnapshot = await getDocs(query(collection(db, 'users'), where('uid', '==', user?.uid)));
      const data = querySnapshot.docs[0].data();
      const document_id = querySnapshot.docs[0].id;
      if (querySnapshot.docs.length === 0) {
        return navigate("/");
      }
      const { ach_payment, authProvider, beta_test, email, events, identity_method_id, name, payment_method_ids, phone, uid, added_events, customer_id } = data;

      setAuthCheck(true);
      setEmail(email);
      setName(name);
      setAuthProvider(authProvider)
      setBetaTest(beta_test)
      setUid(uid);
      setPhone(phone);

      if(name === "" && payment_method_ids !== []){
        console.log("working");
        setNameUsingPaymentFunc(payment_method_ids);
      }

      if(payment_method_ids !== []){
        setPaymentMethod(payment_method_ids)
        getPaymentInfo(payment_method_ids);
      }

      if(data.identity_method_id !== ''){
        setIdentity(identity_method_id);
      }

      if(ach_payment !== ''){
        setACH(ach_payment);
      }

      if(added_events !== [] && added_events !== null){
        setAttending(added_events);
      }

      if(customer_id !== "" && customer_id !== null){
        setCustomer(customer_id)
        fetchTransactions(customer_id);
        getEvents(customer_id);
        getAllCustomerEvents(customer_id);
        getWalletAmount(customer_id);
      } else if(customer_id === ""){
        createCustomer(data, document_id);
      }
    } catch (error) {
      console.error(error);
      navigate("/");
    }
  };


  const getWalletAmount = async (customer) => {
    const data = {
      customer_id: customer
    };
  
    try {
      const response = await axios.post(api + '/get-customer', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        setWallet(res.balance);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTransactions = async (customerId) => {
    try {
      const response = await axios.post(`${api}/fetch-charges/customer`, {
        customer: customerId,
      });
      if (response.status === 200) {
        const transactions = response.data.data;
        setTransactions(transactions);
      } else {
        console.error('Failed to fetch charges');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getEvents = async (customer) => {
    const data = {
      customer: customer,
    };
  
    try {
      const response = await axios.post(`${api}/get-events`, data);
      if (response.status === 200) {
        const res = response.data;
        setEvents(res.events);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllCustomerEvents = async (customer) => {
    const data = {
      customer: customer,
    };
    try {
      const response = await axios.post(`${api}/get-all-customer-events`, data);
      if (response.status === 200) {
        const res = response.data;
        setCustomerEvents(res);
      }
    } catch (err) {
      console.error(err);
    }
  };


  const createCustomer = async (data, document_id)=>{
    try{
      const response = await axios.post(api + '/create-customer', data, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.status === 200) {
        const res = response.data;
        const userDocRef = doc(db, 'users', document_id);
        await updateDoc(userDocRef, {
          customer_id: res.id
        });
      }
    } catch(err){
      console.error(err);
    }
  }

  const getPaymentInfo = async (payment_method_id) => {
    const data = {
      payment_method_id: payment_method_id[0]
    };
  
    try {
      const response = await axios.post(api + '/get-payment-method', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        setBrand(res.card.brand);
        setLast4(res.card.last4);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const setNameUsingPaymentFunc = async (payment_method_id) => {
    const data = {
      payment_method_id: payment_method_id[0]
    };
  
    try {
      const response = await axios.post(api + '/get-payment-method', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        setName(res.billing_details.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user && location.pathname !== "/login"){
      return navigate("/");
    }else if(user) {
      fetchUserData();
    }
  }, [user, loading, location.pathname, navigate]);

  return { customer, attending, events, name, email, ach, authProvider, betaTest, identity, paymentMethod, uid, phone, authCheck, transactions, customerEvents, wallet, brand, last4, setEvents};
};
  