import React from "react";
import "../styles/chargeDetailsOverlay.css";

function ChargeDetailsOverlay({ charge, onClose }) {
  return (
    <div className="overlay">
      <div className="charge-details">
        <h3>Charge Details</h3>
        <p><strong>Amount:</strong> ${(charge.amount * 0.01).toFixed(2)}</p>
        <p><strong>Date:</strong> {new Date(charge.created * 1000).toLocaleDateString()}</p>
        {charge.metadata.coat_number !== undefined &&
                <p><strong>Coat Check:</strong> {charge.metadata.coat_number}</p>
        }
        {/* <br></br> */}
        
        <p className="overlay-margin"><strong><a href={charge.receipt_url}>Receipt</a></strong></p>
        <button className="login-button overlay-margin" onClick={onClose}>Close</button>
        {/* Add other charge details here as needed */}
      </div>
    </div>
  );
}

export default ChargeDetailsOverlay;
