import React, {useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import QRCode from "react-qr-code";
import Header from '../components/header';
import '../styles/dashboard.css'
import applePass from '../assets/apple.svg'
import { api } from '../config/config';
import { useUserData } from "../components/hooks/userData";
import axios from 'axios';



export default function Dashboard() {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const { customer, attending, events, name, email, ach, authProvider, betaTest, identity, paymentMethod, uid, phone, authCheck, transactions, customerEvents, wallet, brand, last4 } = useUserData();


  const createPass = async () => {
    const data = {
      customer: customer,
      email: email,
      payment_method: paymentMethod[0],
      brand: brand,
      last4: last4,
      wallet: wallet
    };
  
    try {
      // Show the loader
      const loader = document.createElement('div');
      loader.className = 'loader';
      document.body.appendChild(loader);
  
      const response = await axios.post(api + '/create-pass', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        const storage = getStorage();
        const gsReference = ref(storage, 'gs://payme-eb477.appspot.com/' + res.pkpass + '.pkpass');
        const url = await getDownloadURL(gsReference);
        const element = document.createElement('a');
        element.href = url;
        document.body.appendChild(element);
        element.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Hide the loader
      const loader = document.querySelector('.loader');
      if (loader) {
        loader.parentNode.removeChild(loader);
      }
    }
  };
  



  const saveIdentity = async (evt) => {
    evt.preventDefault();
    const data = {
      customer_id: customer
    };
  
    try {
      const response = await axios.post(api + '/save-identity', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const session = response.data;
        window.open(session.url);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const savePaymentMethod = async (evt) => {
    evt.preventDefault();
  
    const data = {
      customer_id: customer
    };
  
    try {
      const response = await axios.post(api + '/save-payment-method', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        console.log(res);
        window.location.replace(res.url);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const connectBank = async (evt) => {
    evt.preventDefault();
  
    const data = {
      customer_id: customer
    };
  
    try {
      const response = await axios.post(api + '/save-ach', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        console.log(res);
        window.location.replace(res.url);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addFunds = async (evt) => {
    evt.preventDefault();
    const data = {
      customer_id: customer,
      amount: amount,
      ach: ach
    };
  
    try {
      const response = await axios.post(api + '/save-funds', data, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (response.status === 200) {
        const res = response.data;
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="dashboard-container">
      <Header />
       <div className="dashboard-content">

        <div className="welcome-content">
          <h1>Welcome {name}</h1>
          <p>Email: {email}</p>
          {identity !== "" && <p>Verified</p>}
        </div>

        <div className="save-payment-method-button">
            {paymentMethod.length === 0 &&
              <button className="login-button" onClick={savePaymentMethod}>Save a Payment Method</button>
            }
        </div>
        
        <div className="qr-code">
            {paymentMethod.length !== 0 &&
              <QRCode value={ customer + ' ' + paymentMethod} />
            }
        </div>

        <div className="default-card">
          {paymentMethod.length > 0 &&
                <div>
                  <p>{brand}</p>
                  <p>{last4}</p>
                </div>
          }
          {/* {wallet !== "" &&
                <div>
                  <br></br>
                  <p>Wallet Funds : {wallet}</p>
                </div>
          } */}
        </div>
        

        <div className="save-payment-method-button">
          {/* {identityMethod === "" &&
                <button className="dashboard-button" onClick={saveIdentity}>Verify your account</button>
          } */}
        </div>
         {/* <button className="dashboard-button apple-pass" onClick={createPass}>
          <img src={applePass}></img>
         </button> */}

          {/* <a href={file} download>Click to download</a> */}

          

          {paymentMethod.length !== 0 &&
          <button className="dashboard-button apple-pass" onClick={createPass}>
            <img src={applePass}></img>
            {/* <div id="loader">Loading...</div> */}
          </button>
           }

            {paymentMethod.length !== 0 &&
              <button className="login-button margin-top" onClick={savePaymentMethod}>Update Payment Method</button>
            }
          {/* 
          {paymentMethod.length !== 0 &&
          <div className="apple-pass">
              <a className="apple-pass-image" href={file} download>
                <img src={applePass}></img>
              </a>
           </div>
         } */}
          {/* {ach === "" &&
            <button className="dashboard-button" onClick={connectBank}>
            Connect Bank Account
          </button> } */}
          {/* {ach !== "" &&
          <div>
              <br></br>
              <input
                  placeholder="$50.00"
                  id="create-payment-form-description"
                  amount={amount}
                  className="input-form"
                  onChange={e => setAmount(e.target.value)}
              />

              <button className="dashboard-button" onClick={addFunds}>
              Add Funds
              </button>
          </div>
          } */}
         {/* <button className="dashboard-button" onClick={logout}>
          Logout
         </button> */}
       </div>
     </div>
  )
}