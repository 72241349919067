import React from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import QRCode from "react-qr-code";
import Header from '../components/header';
import '../styles/events.css'
import applePass from '../assets/apple.svg'
import { useUserData } from "../components/hooks/userData";
import { api } from '../config/config';


export default function Events() {
  const {customer, email, customerEvents} = useUserData();


  const createPass = async (event_code, name, date) => {
    const data = {
      customer: customer,
      event_code: event_code,
      name: name,
      date: date,
      email: email
    };
  
    try {
      // Show the loader
      const loader = document.createElement('div');
      loader.className = 'loader';
      document.body.appendChild(loader);
  
      const response = await fetch(api + '/create-event-pass', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        const res = await response.json();
        const storage = getStorage();
        const gsReference = ref(storage, 'gs://payme-eb477.appspot.com/' + res.pkpass + '.pkpass');
        const url = await getDownloadURL(gsReference);
        const element = document.createElement("a");
        element.href = url;
        document.body.appendChild(element);
        element.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Hide the loader
      const loader = document.querySelector('.loader');
      if (loader) {
        loader.parentNode.removeChild(loader);
      }
    }
  };
  


  return (
    <div className="events-container">
    <Header />
     <div className="events-content">
      <h1>Events</h1>
      {customerEvents.map((event, index) => (
        <div key={index} className="event">
          <p className="amount-text">{event.name}</p>
          <p className="date-text">{event.date}</p>
          <QRCode className="smaller" value={customer + ' ' + event.event_code} />
          <button className="events-button apple-pass" onClick={() => createPass(event.event_code, event.name, event.date)}>
            <img src={applePass} alt="Apple Pass" />
          </button>
        </div>
      ))}
      {customerEvents.length === 0  && <p className="date-text">No Events</p>}
     </div>
   </div>
  )
}