import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../components/header';
import '../styles/attend.css'
import { useUserData } from "../components/hooks/userData";
import { api } from '../config/config';


export default function Attend() {
  const navigate = useNavigate();
  const [event, setEvent] = useState("");
  const { customer } = useUserData();
  
  const addEvent = async (evt)=>{
    evt.preventDefault();

    const data = {
      customer: customer,
      event_code: event
    }

    const response = await fetch(api + '/attend-event', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      console.log(res);

      if(res.msg === 'done'){
        window.location.href = '/events';
      }
    }
  }

  return (
    <div className="attend-container">
    <Header />
     <div className="attend-content">
      <h1 id="left-align" className="header-text">Enter Event Code</h1>
      <br></br>
      <input
            placeholder="Code"
            id="create-payment-form-description"
            className="input-form attend-input"
            maxLength="6"
            onChange={e => setEvent(e.target.value)}
      />
      <button className="login-button margin-top-create" onClick={addEvent}>Attend</button>
      <br></br>
     </div>
   </div>
  )
}