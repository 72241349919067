import React, { useState } from "react";
import Header from '../components/header';
import '../styles/manage.css'
import { useUserData } from "../components/hooks/userData";
import axios from 'axios';
import { api } from '../config/config';

export default function ManagePass() {
  const { events, setEvents } = useUserData();
  const [isCopied, setIsCopied] = useState(false);
  const [showAttendees, setShowAttendees] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({ show: false, eventCode: null });

  const getAttendees = async (event_code) => {
    navigator.clipboard.writeText(event_code);
    setIsCopied(true);

    const data = {
      event_code: event_code
    };

    try {
      const response = await axios.post(`${api}/get-attendees`, data);
      if (response.status === 200) {
        const res = response.data;
        setAttendees(res);
        setShowAttendees(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteEvent = async (eventCode) => {
    try {
      const response = await axios.delete(`${api}/events/${eventCode}`);
      if (response.status === 204) {
        const updatedEvents = events.filter(event => event.event_code !== eventCode);
        setEvents(updatedEvents);
        console.log(updatedEvents);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const showDeleteConfirmation = (eventCode) => {
    setConfirmDelete({ show: true, eventCode });
  }

  const handleDeleteConfirmation = async () => {
    if (confirmDelete.eventCode) {
      await deleteEvent(confirmDelete.eventCode);
      setConfirmDelete({ show: false, eventCode: null });
    }
  }

  return (
    <div className="manage-pass-container">
      <Header />
      <div className="manage-events-tagline">
        <h1 className="">Manage Events</h1>
      </div>
      <div className="manage-pass-content">
        {events.length > 0 ? (
          events.map((event, index) => (
            <div key={index} className="pass">
              <p className="amount-text">{event.name}</p>
              <p className="date-text">{event.date}</p>
              <p id="event-code" className="date-text no-transform">{event.event_code}</p>
              <button className="login-button manage-pass-button" onClick={() => getAttendees(event.event_code)}>View Attendees</button>
              <button className="login-button manage-pass-button" onClick={() => showDeleteConfirmation(event.event_code)}>Delete</button>
            </div>
          ))
        ) : (
          <p className="no-events-message">No events</p>
        )}
      </div>

      {showAttendees && (
        <div className="attendees-overlay">
          <div className="attendees-box">
            <h2>Attendees</h2>
            {attendees.length > 0 ? (
              <ul>
                {attendees.map((attendee, index) => (
                  <li key={index}>{attendee.customer_id} ({attendee.email})</li>
                ))}
              </ul>
            ) : (
              <p className="no-attendees">No attendees</p>
            )}
            <button className="login-button" onClick={() => setShowAttendees(false)}>Close</button>
          </div>
        </div>
      )}

      {confirmDelete.show && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-box">
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete this event?</p>
            <button className="login-button" onClick={handleDeleteConfirmation}>Yes, Delete</button>
            <button className="login-button" onClick={() => setConfirmDelete({ show: false, eventCode: null })}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}