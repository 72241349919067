import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogle} from "../config/firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import Header from '../components/header';
import AuthCodeInput from '../components/AuthCodeInput';
import '../styles/register.css'


function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState("");
  const [phoneNumberLength, setPhoneNumberLength] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const firebaseConfig = {
    apiKey: "AIzaSyDUyRo_kOxmDZsCdBeulqJYw51yjCAlB9I",
    authDomain: "payme-eb477.firebaseapp.com",
    projectId: "payme-eb477",
    storageBucket: "payme-eb477.appspot.com",
    messagingSenderId: "98790175624",
    appId: "1:98790175624:web:4cdceea254645c626b403d",
    measurementId: "G-4C4T6BX8B2"
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);



  const generateRecaptcha = () =>{
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size':'invisible',
      'callback': (response) =>{
      }
    }, auth)
  }

  const removeCharsFromArray = (chars) =>{
    const filteredChars = chars.filter(char => !["(", ")", "-", " "].includes(char));
    return filteredChars;
  }

  const handleChange = (event) => {
    let x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let formattedPhone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    setPhone(formattedPhone);
  
    if (formattedPhone.length === 14) {
      setPhoneNumberLength(true);
    } else {
      setPhoneNumberLength(false);
    }
  };

  const signInWithPhone = async (evt) =>{
    evt.preventDefault();
    const number = removeCharsFromArray(phone.split(""));
    let result = '+1'.concat(...number);

    if(result.length >= 10){
      setExpandForm(true);
      generateRecaptcha()
      let appverifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, result, appverifier)
      .then(confirmationResult =>{
        window.confirmationResult = confirmationResult;
        console.log(confirmationResult)
      }).catch((err)=>{
        // console.log(err)
        console.log("hello");
        setErrorMessage("Invalid Phone Number")
      })
    }
  }

  const handleCodeEntered = async (code) =>{
    if(code.length === 6){
      console.log(code);
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(code).then(async (result) =>{
        const user = result.user;
        console.log(result.user);
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: "",
            email:"",
            ach_payment:"",
            added_events:"",
            beta_test:false,
            events:[],
            identity_method_id:"",
            authProvider: "phone",
            phone: user.phoneNumber,
            customer_id: '',
            payment_method_ids: [],
            identity_method_id: '',
          });
        } else{
          console.log("user already has an account");
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  }



  const register = async (evt) =>{
    evt.preventDefault();
    const response = await registerWithEmailAndPassword(name,email,password);
    console.log(response);
  }

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/password");
  }, [user, loading]);


  return (
    <div className="register-container">
      <Header />
      <div className="register-content">
            {!expandForm && 
              <div>
                <h1 id="left-align" className="header-text">Register/Login with phone</h1>
                  <input
                      placeholder="(555) 555-5555"
                      id="phone-number"
                      value={phone}
                      type="tel"
                      className="input-form"
                      onChange={handleChange}
                  />
                {phoneNumberLength &&
                <button className="login-button margin-top" onClick={signInWithPhone}>
                  Submit
                </button>
                }
              </div>
            }

            {expandForm &&
            <div>
              <h1 id="left-align" className="header-text">Enter Code</h1>
              <AuthCodeInput onCodeEntered={handleCodeEntered} />
            </div>
            }
            <div id="recaptcha-container"></div>
        </div>
    </div>
  );
}

export default Register;