import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../components/header';
import { useUserData } from "../components/hooks/userData";
import '../styles/create.css'
import axios from 'axios';
import { api } from '../config/config';

export default function CreatePass() {
  const {customer } = useUserData();
  const navigate = useNavigate();
  const [eventname, setEventName] = useState("");
  const [date, setDate] = useState("");
 

  function handleDateChange(date) {
    setDate(date);
    console.log(date);
  }

  const generateCode = async (evt) => {
    evt.preventDefault();
    const data = {
      eventName: eventname,
      date: date,
      customer: customer,
    };
  
    try {
      const response = await axios.post(`${api}/generate-code`, data);
      if (response.status === 200) {
        const res = response.data;
        if (res.event_code !== '' && res.event_code) {
          window.location.href = '/manage-pass';
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  function getCurrentTime() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }


  return (
    <div className="create-pass-container">
      <Header />
       <div className="create-pass-content">
        <h1>Create Pass</h1>
        <br></br>
           <input
                  placeholder="Event Name"
                  id="create-payment-form-description"
                  eventname={eventname}
                  className="input-form create-form"
                  onChange={e => setEventName(e.target.value)}
            />
           <input
                  placeholder="date - MM/DD/YYYY"
                  type="datetime-local"
                  id="create-payment-form-description"
                  date={date}
                  value={date ? date : getCurrentTime()}
                  className="date-input-form"
                  onChange={e => setDate(e.target.value)}
            />
            <button className="login-button margin-top-create" onClick={generateCode} disabled={!eventname || !date}>Generate Code</button>
       </div>
     </div>
  )
}