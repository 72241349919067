// import React, {useEffect, useState} from "react";
// import Header from '../components/header';
// import ChargeList from '../components/ChargeList';
// import '../styles/transactions.css'
// import { useUserData } from "../components/hooks/userData";

// function Loader() {
//   return <div className="loader-transactions"></div>;
// }

// export default function Transactions(props) {
//   const {transactions } = useUserData();
//   const [isLoading, setIsLoading] = useState(true);
  
//   function formatPrice(price) {
//     return `$${(price * .01).toFixed(2)}`;
//   }

//   const totalSpend = (charges)=>{
//     if(charges){
//       const spend = charges.reduce((total, transaction) => {
//         return total + transaction.amount;
//       }, 0);
//       return spend;
//     } else{
//       return 0;
//     }
//   }

//   useEffect(() => {
//     if (transactions) {
//       setIsLoading(false);
//     }
//   }, [transactions]);

//   return (
//     <div className="transactions-container">
//       <Header />
//       <div className="summary-view">
//         <p className="amount-text">{formatPrice(totalSpend(transactions))}</p>
//         <p className="date-text">Total Spend </p>
//       </div>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <ChargeList charges={transactions} pageSize={10} />

//       )}
//      </div>
//   )
// }


import React, { useEffect, useState } from "react";
import Header from "../components/header";
import ChargeList from "../components/ChargeList";
import ChargeDetailsOverlay from "../components/ChargeDetailsOverlay";
import "../styles/transactions.css";
import { useUserData } from "../components/hooks/userData";

function Loader() {
  return <div className="loader-transactions"></div>;
}

export default function Transactions(props) {
  const { transactions } = useUserData();
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);

  function formatPrice(price) {
    return `$${(price * 0.01).toFixed(2)}`;
  }

  const totalSpend = (charges) => {

    console.log(charges);
    if (charges) {
      const spend = charges.reduce((total, transaction) => {
        return total + transaction.amount;
      }, 0);
      return spend;
    } else {
      return 0;
    }
  };

  const handleChargeClick = (charge) => {
    setSelectedCharge(charge);
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  useEffect(() => {
    if (transactions) {
      setIsLoading(false);
    }
  }, [transactions]);

  return (
    <div className="transactions-container">
      <Header />
      <div className="summary-view">
        <p className="amount-text">{formatPrice(totalSpend(transactions))}</p>
        <p className="date-text">Total Spend </p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ChargeList charges={transactions} pageSize={10} onChargeClick={handleChargeClick} />
      )}
      {showOverlay && selectedCharge && (
        <ChargeDetailsOverlay charge={selectedCharge} onClose={closeOverlay} />
      )}
    </div>
  );
}
