import Reach, { useState, Switch } from "react";
import Home from "../pages/home";
import Register from "../pages/register";
import Dashboard from "../pages/dashboard";
import Transactions from "../pages/transactions";
import Reset from "../pages/reset";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PaymentMethodSuccess from "../pages/paymentMethodSuccess";
import AchPaymentMethodSuccess from "../pages/achPaymentSuccess";
import Password from "../pages/password";
import CreatePass from "../pages/createPass";
import ManagePass from "../pages/managePass";
import Privacy from "../pages/privacy";
import Attend from "../pages/attend";
import Events from "../pages/events";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/password" element={<Password />} />
          <Route path="/login" element={<Register />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-pass" element={<CreatePass />} />
          <Route path="/manage-pass" element={<ManagePass />} />
          <Route path="/attend" element={<Attend />} />
          <Route path="/events" element={<Events />} />
          <Route path="/privacy" element={<Privacy />} />
          {/* <Route path="/dashboard-restaurant" element={<DashboardRestaurant />} /> */}
          {/* <Route path="/reset" element={<Reset />} /> */}
          <Route path="/payment-method-success" element={<PaymentMethodSuccess />} />
          <Route path="/payment-method-success-ach" element={<AchPaymentMethodSuccess />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;