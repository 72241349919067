import React, { useState, useRef } from "react";
import '../styles/AuthCodeInput.css';


const AuthCodeInput = ({ onCodeEntered }) => {
  const [code, setCode] = useState("");
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const handleInput = (index, event) => {
    const { value } = event.target;

    if (isNaN(value)) {
      return;
    }

    setCode((prevCode) => {
      const newCode = prevCode.split("");
      newCode[index] = value;
      return newCode.join("");
    });

    if (index < 5 && value) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onCodeEntered(code);
  };

  return (
    <form className="auth-code-input" onSubmit={handleSubmit}>
      {inputRefs.map((inputRef, index) => (
        <input
          key={index}
          type="tel"
          maxLength={1}
          value={code[index] || ""}
          onChange={(event) => handleInput(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          ref={inputRef}
        />
      ))}
      <button className="login-button" type="submit">Submit</button>
    </form>
  );
};

export default AuthCodeInput;