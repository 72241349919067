import Reach, { useState } from "react";
import "../styles/header.css"
import { Link } from "react-router-dom";
import { logout } from "../config/firebase-config";
import { useUserData } from "../components/hooks/userData";


export default function Header(){
  const [completed, setCompleted] = useState(false);
  const { authCheck, betaTest } = useUserData();

  const changeState = () =>{
    setCompleted(!completed);
  }

  return(

    <div className="header-container">
      <div className={completed ? 'header active' : "header"}>
        <svg onClick={changeState} id="menu-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.33 149.26">
          <rect  className={completed ? 'menu close' : "menu open"} id="top" width="348.33" height="28.7"/>
          <rect  className={completed ? 'menu close' : "menu open"} id="middle" y="60.28" width="348.33" height="28.7"/>
          <rect  className={completed ? 'menu close' : "menu open"} id="bottom" y="120.56" width="348.33" height="28.7"/>
        </svg>
        <h1><Link className="new-font" to="/">Falcon Pay</Link></h1>
      </div>

      <div className={completed ? 'header-container-m show' : "header-container-m inactive"}>
        <div className="menu-content">
          <ul>
            {authCheck === true && betaTest === true && <li onClick={changeState}> <Link to="/dashboard">Pay</Link></li>}
            {authCheck === true && betaTest === true && <li onClick={changeState}> <Link to="/transactions">Transactions</Link></li>}
            {authCheck === true && betaTest === true && <li onClick={changeState}> <Link to="/create-pass">Create Event</Link></li>}
            {authCheck === true && betaTest === true && <li onClick={changeState}> <Link to="/manage-pass">Manage Events</Link></li>}
            {authCheck === false &&  <li onClick={changeState}> <Link to="/login">Login</Link></li>}
            {authCheck === true && betaTest === true &&  <li onClick={changeState}> <Link to="/events">Event Passes</Link></li>}
            {authCheck === true && betaTest === true &&  <li onClick={changeState}> <Link to="/attend">Attend</Link></li>}
            <li onClick={changeState}><Link to="/privacy">Privacy Policy</Link></li>
            {authCheck === true &&  <li onClick={changeState}> <a onClick={logout}>Logout</a></li>}
          </ul>
        </div>
      </div>
    </div>
  )
}