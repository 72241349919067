import React from 'react'
import Reach, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db, logInWithEmailAndPassword, signInWithGoogleRestaurant, logout } from "../config/firebase-config";
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import Header from '../components/header';
import "../styles/password.css"
import { api } from '../config/config';


function Password() {
  const [user, loading, error] = useAuthState(auth);
  const [completed, setCompleted] = useState(false);
  const [authCheck, setAuthCheck] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false)
  const [accessCode, setAccessCode] = useState("");
  const [userData, setUserData] = useState({});
  const [docId, setdocId] = useState("");
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const docz = await getDocs(q);
      const data = docz.docs[0].data();
      setUserData(data)
      setdocId(docz.docs[0].id);
      if(data.beta_test === true){
        navigate('/dashboard');
      }
    } catch (err) {
      console.log("no user found")
    }
  };


  const confirmAccessCode = async ()=>{

    const data = {
      access_code:accessCode,
      doc_id:docId
    }

    const response = await fetch(api + '/check-access-code', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      const userDocRef = doc(db, 'users', docId);

      if(res.msg === "success"){
        navigate('/dashboard');
      } else{
        console.log(res.err);
      }
    }
  }

  useEffect(() => {
    if (loading) return;
    if (user){
      setAuthCheck(true)
      fetchUserInfo()
    }
  }, [user, loading]);


  return (
      <div className="password-container">
        <Header />
          {!adminCheck &&
            <div className="password-content-container">
            <h1>Thank you for expressing interest. Our team is working diligently to finalize our offering and we will be in touch with you shortly to let you know when it is available. If you have an early access code, enter it below to get a first look at our offering.</h1>
            <div className='password-content'>
              <input
                placeholder="Code"
                id="create-payment-form-description"
                className="input-form"
                maxLength="6"
                onChange={e => setAccessCode(e.target.value)}
            />
          <button className="login-button" onClick={confirmAccessCode}>Submit</button>
          </div>
        </div>
          }
      </div>
  );
}

export default Password;