// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUyRo_kOxmDZsCdBeulqJYw51yjCAlB9I",
  authDomain: "payme-eb477.firebaseapp.com",
  projectId: "payme-eb477",
  storageBucket: "payme-eb477.appspot.com",
  messagingSenderId: "98790175624",
  appId: "1:98790175624:web:4cdceea254645c626b403d",
  measurementId: "G-4C4T6BX8B2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        customer_id: '',
        payment_method_ids: [],
        identity_method_id: '',
      });
    } else{
      console.log("user already has an account");
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithGoogleRestaurant = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "restaurants"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "restaurants"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        account_id: '',
      });
    } else{
      console.log("restaurant already has an account");
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithPhone = async (phoneNumber) =>{

  try{
    if (phoneNumber === "" || phoneNumber.length < 10) return;

    let verify = new RecaptchaVerifier('recaptcha-container');
    const res = await signInWithPhoneNumber(phoneNumber, verify);
  
    if(res.ok){
      console.log(res);
    } else{
      console.log("error happened");
    }
  } catch(err){
    console.log(err);
  }
}

// const registerWithPhone = async (phone) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name: user.displayName,
//       authProvider: "google",
//       email: user.email,
//       customer_id: '',
//       payment_method_ids: [],
//       identity_method_id: '',
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
  // window.location.href('/');
  window.location.href = "https://falconpay.app";
  // window.location.href = "http://localhost:3001/";
  // window.location.reload();
};


export {
  auth,
  db,
  signInWithGoogle,
  signInWithGoogleRestaurant,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};